.category-row-container {
    padding-bottom: 2vh;
    display: flex;
    justify-content: space-around;
}

.image-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20vw;
    height: 10vh
}

.category-name {
    align-self: center;
    font-size: 60%;
    font-weight: 500;
    padding: 0;
}