.feedback-desc-container{
    height: 70vh;
}

.feedback-writing-area{
    height: 100%;
    width: 100%;
    font-family: Roboto;
    font-size: 3vh;
}

.send-feedback-button{
    padding-top: 2vh;
}