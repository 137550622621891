.budget-ok {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.selected-budget-category-container{
    height: 10vh;
    margin-left: 2vw;
    margin-top: 1vh;
    font-size: 110%;
}

.repeat-monthly-toggle-container{
    margin-left: 50vw;
    margin-top: 1vh;
}