.onBoard-card-container {
    background-color: #DCF4FD;
}

.description-container {
    padding-bottom: 6vh;
    padding-top: 6vh;
    font-weight: normal;
    font-size: 0.7em;
    color: gray;
}

.onBoard-title-container {
    padding-top: 6vh;
    padding-bottom: 6vh;
}
