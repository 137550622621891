.overview-detail-card-header-amount {
    font-size: 90%;
    font-weight: 500;
}

.overview-detail-card-header-label {
    padding-top: 0.5vh;
    color: #9B9B9B;
    font-size: 50%;
    font-weight: 500;
}