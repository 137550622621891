.feature-icon {
  height: 40px;
}

.feature-title {
  padding: 2vh;
}

.feature-description {
  font-size: 85%;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .feature-icon {
    height: 30px;
  }

  .feature-title {
    padding: 1vh;
  }
}
