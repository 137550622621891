.landing-page-mobile-banner {
  padding-top: 4vh;
  padding-bottom: 4vh;
  height: 50vh;
}

.landing-page-mobile-image-button {
  height: 70%;
}

.mobile-get-started-button {
  padding-top: 10vh;
}
