.input-box-sc {
    height: 10vh;
    width: 80%;
    display: flex;
    margin-top: 2em;
    border: 0.07em solid gray;
    margin-left: 9vw;
    flex-direction: row;
}

.input-box-sc-title-name {
    font-size: 60%;
    font-weight: 400;
    color: #5D7079;
    margin-top: 1vh;
    width: 40vw;
    margin-left: 2vw;
}

