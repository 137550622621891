.team {
  padding-top: 6vh;
  padding-bottom: 6vh;
  /*border: 1px solid red;*/
}

.team-headline {
  font-size: 200%;
  font-weight: 300;
  color: #297fca;
  padding-top: 6vh;
  padding-bottom: 6vh;
  text-align: center;
}

.team-photo {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.team-member {
  background-color: #ffffff;
  padding-bottom: 6vh;
  border-radius: 1%;
}

.team-member-name {
  font-size: 120%;
  color: #38527c;
  padding-top: 2vh;
}

.team-member-title {
  font-size: 80%;
  color: #38527c;
  padding-top: 0.5vh;
}

.team-member-social-handles {
  padding-top: 2vh;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .team-photo {
    height: 150px;
    width: 150px;
  }
}
