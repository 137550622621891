.tag-line {
  margin-top: 10vh;
  margin-bottom: 9vh;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .tag-line {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
}
