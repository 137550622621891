.create-budget-amount-container {
    height: 100%;
}

.budget-amount-entered {
    height: 10vh;
    background-color: #416094;
    font-size: 250%;
    font-weight: 200;
    color: #FFFFFF;
    padding-top: 2vh;
    padding-left: 6vw;
}