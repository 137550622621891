.input-box {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.input-box-review-page {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
}


.input-box-border {
    width: 80%;
    border: 0.07em solid gray;
    border-radius: 2px;
}

.input-box-title {
    font-size: 60%;
    font-weight: 400;
    color: #5D7079;
    margin-top: 1vh;
}

.input-box-title,
.input-box-children,
.input-box-children-review-page {
    margin-left: 2vw;
}