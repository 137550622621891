.app-version {
  display: flex;
  justify-content: center;
  color: #6f8691;
  font-size: 85%;
  width: 100%;
  position: absolute;
  bottom: 10vh;
}

.privacy-style {
  display: flex;
  justify-content: left;
  color: #6f8691;
  font-size: 80%;
  width: 100%;
  position: absolute;
  bottom: 12vh;
  padding-left: 1vw;
  padding-bottom: 2vh;
  text-decoration: none;
}

.terms-style {
  display: flex;
  color: #6f8691;
  font-size: 80%;
  width: 100%;
  position: absolute;
  bottom: 10vh;
  padding-left: 1vw;
  padding-bottom: 1vh;
  text-decoration: none;
}
