.app-card-row {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.app-card-row-row {
    width: 99.9%;
}

.app-card-row-body {
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-bottom: 1vh;
}