body {
  font-family: 'Roboto', sans-serif;
}

/* https://stackoverflow.com/a/15089567/379235 */
body,
html {
}

body,
html,
#root,
#root > div {
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
