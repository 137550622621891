.overview-detail-transaction {
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.overview-detail-transaction-day-of-month {
    font-size: 80%;
}

.overview-detail-transaction-day-of-week {
    font-size: 40%;
    color: #9B9B9B;
}

.overview-detail-transaction-name {
    font-size: 80%;
}

.overview-detail-transaction-amount {
    font-size: 80%;
    font-weight: 500;
}