.overview-detail-budget-header-item-value {
    margin-bottom: 1vh;
    font-weight: 500;
    font-size: 120%;
}

.overview-detail-budget-header-item-label {
    color: #5D7079;
    font-size: 60%;
    font-weight: 500;
}