.transaction-row {
  align-items: center;
  height: 12vh;
  font-size: 80%;
}

.transaction-weekday {
  font-size: 70%;
  color: #898787;
}

.transaction-debit {
  color: #d50000;
  font-size: 110%;
}

.transaction-credit {
  color: #39cf71;
  font-size: 110%;
}

.transaction-card:last-child {
  padding-bottom: 10vh;
  overflow-x: hidden;
}
