.app-header {
    position: fixed;
    width: 100%;
    height: 10vh;
    background-color: #38527C;
    color: #fff;
}

.app-header-row{
    height: 10vh;
}