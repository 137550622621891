.overview-budget-row-body {
    margin-top: 5vh;
    display: flex;
    align-items: center;
}

.overview-body-over-budget-progress-bar,
.overview-body-in-budget-progress-bar {
    margin-top: 0.2em;
    margin-bottom: 1vh;
    height: 1vh;
    border-radius: 25px;
}

.overview-body-over-budget-progress-bar {
    background-color: #EF9A9A;
}