.pricing-headline {
  color: #297fca;
  padding-top: 6vh;
  padding-bottom: 6vh;
  text-align: center;
}

.price-card {
  border: 0.02vw solid #e4f1fd;
}

.price-card-label {
  padding-top: 2em;
  padding-bottom: 1em;
}

.price-card-amount {
  padding-top: 0.5em;
  color: #00b9ff;
}

.price-card-subtitle {
  padding: 0.5em;
}

.price-card-description {
  padding: 2em 2em 3em;
  color: #00b9ff;
}

.price-card-coming-soon {
  color: #39cf71;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .price-card {
    border: 0.5vw solid #e4f1fd;
  }

  .price-card-label {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  .price-card-description {
    padding: 1em 1em 2em;
    color: #00b9ff;
  }
}
