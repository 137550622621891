body {
  font-family: 'Roboto', sans-serif;
}

/* https://stackoverflow.com/a/15089567/379235 */
body,
html {
}

body,
html,
#root,
#root > div {
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.input-box {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.input-box-review-page {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
}


.input-box-border {
    width: 80%;
    border: 0.07em solid gray;
    border-radius: 2px;
}

.input-box-title {
    font-size: 60%;
    font-weight: 400;
    color: #5D7079;
    margin-top: 1vh;
}

.input-box-title,
.input-box-children,
.input-box-children-review-page {
    margin-left: 2vw;
}
.input-box-sc {
    height: 10vh;
    width: 80%;
    display: flex;
    margin-top: 2em;
    border: 0.07em solid gray;
    margin-left: 9vw;
    flex-direction: row;
}

.input-box-sc-title-name {
    font-size: 60%;
    font-weight: 400;
    color: #5D7079;
    margin-top: 1vh;
    width: 40vw;
    margin-left: 2vw;
}


.category-row-container {
    padding-bottom: 2vh;
    display: flex;
    justify-content: space-around;
}

.image-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20vw;
    height: 10vh
}

.category-name {
    align-self: center;
    font-size: 60%;
    font-weight: 500;
    padding: 0;
}
.app-header {
    position: fixed;
    width: 100%;
    height: 10vh;
    background-color: #38527C;
    color: #fff;
}

.app-header-row{
    height: 10vh;
}
.transaction-row {
  align-items: center;
  height: 12vh;
  font-size: 80%;
}

.transaction-weekday {
  font-size: 70%;
  color: #898787;
}

.transaction-debit {
  color: #d50000;
  font-size: 110%;
}

.transaction-credit {
  color: #39cf71;
  font-size: 110%;
}

.transaction-card:last-child {
  padding-bottom: 10vh;
  overflow-x: hidden;
}

.onBoard-card-container {
    background-color: #DCF4FD;
}

.description-container {
    padding-bottom: 6vh;
    padding-top: 6vh;
    font-weight: normal;
    font-size: 0.7em;
    color: gray;
}

.onBoard-title-container {
    padding-top: 6vh;
    padding-bottom: 6vh;
}

.app-version {
  display: flex;
  justify-content: center;
  color: #6f8691;
  font-size: 85%;
  width: 100%;
  position: absolute;
  bottom: 10vh;
}

.privacy-style {
  display: flex;
  justify-content: left;
  color: #6f8691;
  font-size: 80%;
  width: 100%;
  position: absolute;
  bottom: 12vh;
  padding-left: 1vw;
  padding-bottom: 2vh;
  text-decoration: none;
}

.terms-style {
  display: flex;
  color: #6f8691;
  font-size: 80%;
  width: 100%;
  position: absolute;
  bottom: 10vh;
  padding-left: 1vw;
  padding-bottom: 1vh;
  text-decoration: none;
}

.budget-ok {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.selected-budget-category-container{
    height: 10vh;
    margin-left: 2vw;
    margin-top: 1vh;
    font-size: 110%;
}

.repeat-monthly-toggle-container{
    margin-left: 50vw;
    margin-top: 1vh;
}
.create-budget-amount-container {
    height: 100%;
}

.budget-amount-entered {
    height: 10vh;
    background-color: #416094;
    font-size: 250%;
    font-weight: 200;
    color: #FFFFFF;
    padding-top: 2vh;
    padding-left: 6vw;
}
.budget-container{
    margin-top: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 10vh;
}

.budget-card {
    margin-bottom: 1vh;
}

.category-amount-repeat-labels {
    font-size: 60%;
    font-weight: bold;
    color: #5D7079;
}

.budget-category-container{
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-left: 10vw;
}

.budget-category-name{
}

.budget-amount-container{
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-left: 10vw;
}


.budget-edit-delete-container{
    padding-top: 2vh;
    margin-left: 20vw;
}

.budget-edit{
    margin-left: 4vw;
}


.budget-frequency-container{
    margin-left: 20vw;
    padding-top: 2vh;
}

.budget-value {
    font-size: 80%;
}
.feedback-desc-container{
    height: 70vh;
}

.feedback-writing-area{
    height: 100%;
    width: 100%;
    font-family: Roboto;
    font-size: 3vh;
}

.send-feedback-button{
    padding-top: 2vh;
}
.overview-detail-budget-header-item-value {
    margin-bottom: 1vh;
    font-weight: 500;
    font-size: 120%;
}

.overview-detail-budget-header-item-label {
    color: #5D7079;
    font-size: 60%;
    font-weight: 500;
}
.overview-detail-category-name {
    font-size: 80%;
}

.overview-detail-category-days {
    padding-top: 0.5vh;
    font-size: 60%;
    color: #9B9B9B;
}
.overview-detail-budget-header {
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin-bottom: 2vh;
}
.app-card-row {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.app-card-row-row {
    width: 99.9%;
}

.app-card-row-body {
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-bottom: 1vh;
}
.overview-detail-card-header-amount {
    font-size: 90%;
    font-weight: 500;
}

.overview-detail-card-header-label {
    padding-top: 0.5vh;
    color: #9B9B9B;
    font-size: 50%;
    font-weight: 500;
}
.overview-detail-card-subcategory {
    font-size: 95%;
    font-weight: 500;
}

.overview-detail-card-num-transactions {
    padding-top: 1vh;
    font-size: 60%;
    color: #9B9B9B;
}
.overview-detail-transaction {
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.overview-detail-transaction-day-of-month {
    font-size: 80%;
}

.overview-detail-transaction-day-of-week {
    font-size: 40%;
    color: #9B9B9B;
}

.overview-detail-transaction-name {
    font-size: 80%;
}

.overview-detail-transaction-amount {
    font-size: 80%;
    font-weight: 500;
}
.overview-detail-header {
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin-bottom: 2vh;
}
.overview-detail-page {
  padding-bottom: 10vh;
  overflow-x: hidden;
}

.overview-status-item-value {
    margin-bottom: 1vh;
    font-weight: 500;
    font-size: 120%;
}

.overview-status-item-label {
    color: #5D7079;
    font-size: 60%;
    font-weight: 500;
}

.overview-header {
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin-bottom: 2vh;
}
.overview-budget-row-next {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overview-budget-body-transactions {
    font-size: 60%;
    color: #6F8691;
}

.overview-budget-body-amount {
    font-weight: bold;
    margin-left: 0.3em;
    margin-right: 0.3em;
    font-size: 90%;
}

.overview-budget-body-of {
    font-weight: 400;
    color: #6F8691;
    font-size: 80%;
}


.overview-row-header {
    height: 7vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 75%;
}

.overview-budget-row-header-icon-category {
    display: flex;
    align-items: center;
}
.budget-exceeded-status {
    display: flex;
    align-items: center;
}

.budget-exceeded-status-text {
    font-size: 85%;
    color: #5D7079;
}
.in-budget-amount-status {
    font-weight: 500;
}
.overview-body-transactions-amount {
    display: flex;
    justify-content: space-between;
}
.overview-budget-row-body {
    margin-top: 5vh;
    display: flex;
    align-items: center;
}

.overview-body-over-budget-progress-bar,
.overview-body-in-budget-progress-bar {
    margin-top: 0.2em;
    margin-bottom: 1vh;
    height: 1vh;
    border-radius: 25px;
}

.overview-body-over-budget-progress-bar {
    background-color: #EF9A9A;
}
.overview-body-category-name {
    font-size: 80%;
}
.landing-page-header {
  padding-left: 0.5em;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.feature-link {
    color: #FFFFFF;
    font-size: 80%;
    font-weight: 200;
    text-decoration: none;
}
.mobile-menu-item {
    color: #12313C;
    font-size: 90%;
    text-decoration: none;
}
.social-icon {
    font-size: 100%;
}
.feature-icon {
  height: 40px;
}

.feature-title {
  padding: 2vh;
}

.feature-description {
  font-size: 85%;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .feature-icon {
    height: 30px;
  }

  .feature-title {
    padding: 1vh;
  }
}

.pricing-headline {
  color: #297fca;
  padding-top: 6vh;
  padding-bottom: 6vh;
  text-align: center;
}

.price-card {
  border: 0.02vw solid #e4f1fd;
}

.price-card-label {
  padding-top: 2em;
  padding-bottom: 1em;
}

.price-card-amount {
  padding-top: 0.5em;
  color: #00b9ff;
}

.price-card-subtitle {
  padding: 0.5em;
}

.price-card-description {
  padding: 2em 2em 3em;
  color: #00b9ff;
}

.price-card-coming-soon {
  color: #39cf71;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .price-card {
    border: 0.5vw solid #e4f1fd;
  }

  .price-card-label {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  .price-card-description {
    padding: 1em 1em 2em;
    color: #00b9ff;
  }
}

.sign-in-buttons {
    margin-top: 6vh;
    color: #FFFFFF;
    font-size: 70%;
    font-weight: 100;
}

.sign-in-button {
    margin-top: 2vh;
}
.sign-in-header-label {
    padding-top: 2vh;
    font-size: 120%;
    font-weight: 500;
}
.team {
  padding-top: 6vh;
  padding-bottom: 6vh;
  /*border: 1px solid red;*/
}

.team-headline {
  font-size: 200%;
  font-weight: 300;
  color: #297fca;
  padding-top: 6vh;
  padding-bottom: 6vh;
  text-align: center;
}

.team-photo {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.team-member {
  background-color: #ffffff;
  padding-bottom: 6vh;
  border-radius: 1%;
}

.team-member-name {
  font-size: 120%;
  color: #38527c;
  padding-top: 2vh;
}

.team-member-title {
  font-size: 80%;
  color: #38527c;
  padding-top: 0.5vh;
}

.team-member-social-handles {
  padding-top: 2vh;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .team-photo {
    height: 150px;
    width: 150px;
  }
}

.landing-page-banner {
  padding-top: 4vh;
  padding-bottom: 4vh;
  height: 50vh;
}

.landing-page-banner-row {
  height: 100%;
}

.tag-line {
  margin-top: 10vh;
  margin-bottom: 9vh;
}

@media all and (min-width: 360px) and (max-width: 640px) {
  .tag-line {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
}

.banner-image {
    height: 120%;
    width: 100%;
    overflow: hidden;
}

.banner-image img {
    max-width:100%;
    max-height:100%;
}
.landing-page-mobile-banner {
  padding-top: 4vh;
  padding-bottom: 4vh;
  height: 50vh;
}

.landing-page-mobile-image-button {
  height: 70%;
}

.mobile-get-started-button {
  padding-top: 10vh;
}

