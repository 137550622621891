.budget-container{
    margin-top: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 10vh;
}

.budget-card {
    margin-bottom: 1vh;
}

.category-amount-repeat-labels {
    font-size: 60%;
    font-weight: bold;
    color: #5D7079;
}

.budget-category-container{
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-left: 10vw;
}

.budget-category-name{
}

.budget-amount-container{
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-left: 10vw;
}


.budget-edit-delete-container{
    padding-top: 2vh;
    margin-left: 20vw;
}

.budget-edit{
    margin-left: 4vw;
}


.budget-frequency-container{
    margin-left: 20vw;
    padding-top: 2vh;
}

.budget-value {
    font-size: 80%;
}