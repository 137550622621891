.overview-budget-row-next {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overview-budget-body-transactions {
    font-size: 60%;
    color: #6F8691;
}

.overview-budget-body-amount {
    font-weight: bold;
    margin-left: 0.3em;
    margin-right: 0.3em;
    font-size: 90%;
}

.overview-budget-body-of {
    font-weight: 400;
    color: #6F8691;
    font-size: 80%;
}

